/* ====== Footer =====*/
footer {
  color: #020917;
  background-color: #001232;
  padding: 30px 0 0;
}
footer a {
  color: #b2b7c1;
  text-decoration: none;
}
footer ul li,
footer ul {
  margin: 0;
  padding: 0;
}
footer a:hover {
  color: #fff;
  opacity: 1;
}
footer .widget {
  margin-bottom: 10px;
}
footer .widget_title {
  font-size: 22px;
  font-weight: 700;
  color: #b2b7c1;
}

footer .widget_title:hover {
  font-size: 22px;
  font-weight: 700;
  color: white;
}

@media (max-width: 768px) {
  footer .widget {
    margin-bottom: 50px;
  }
}

.copyright {
  background-color: white;
  color: #000;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
}
.copyright p {
  margin-bottom: 0;
  color: #001232;
}
.copyright a,
.copyright a:hover,
.copyright a:active {
  color: #001232;
  opacity: 1;
}

.animated-part {
  display: none;
}
.animated-part.make-visible {
  display: block;
}

.widget.menu ul li {
  padding: 5px 0;
  display: block;
  font-weight: 400;
  font-size: 15px;
  color: #b2b7c1;
}

.widget.menu ul li:hover {
  padding: 5px 0;
  display: block;
  font-weight: 400;
  font-size: 15px;
  color: white;
}

.branchTitle {
  color: #b2b7c1;
}
.branchTitle:hover {
  color: white;
}

@media (min-width: 1008px) {
  .branchTitle {
    color: #b2b7c1;
    /* border-right: 1px solid #a6a6a6; */
    padding-right: 10px;
    font-size: 15px;
  }
  .branchTitle:last-child {
    border: 0;
  }
}

@media (min-width: 1024px) {
  .branchTitle {
    color: #b2b7c1;
    /* border-right: 1px solid #a6a6a6; */
    padding-right: 10px;
    font-size: 12px;
  }
  .branchTitle:last-child {
    border: 0;
  }
}
