.navbar-nav .nav-link {
  font-size: 16.5px;
  font-weight: normal;
  font-family: sans-serif;
  margin-right: 20px;
  color: #b2b7c1 !important;
}

.navbar-nav .nav-link:hover {
  /* background-color: #fff; */
  color: white !important;
}

.anchor {
  color: var(--bs-link-color);
  text-decoration: none;
}

/* .navbar-custom {
  background-color: linear-gradient(red, yellow);
} */

#grad1 {
  /* background-color: red; 
  background-image: linear-gradient(#a7ddf2, #a7e4f2); */
  background-color: #001232;
}

.align-middle {
  color: white;
  cursor: pointer;
}

/* .align-middle:hover {
  color: white;
} */
.my-select .flag-select__option--selected {
  color: #fff;
}

.navbar-toggler-icon {
  background-image: url('./menuIconWhite.png') !important;
}