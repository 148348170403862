.card {
  border: none;
}

.brand {
  font-size: 13px;
}

.act-price {
  color: red;
  font-weight: 700;
}

.dis-price {
  text-decoration: line-through;
}

.about {
  /* font-size: 20px; */
  line-height: 1.8;
  margin-bottom: 14px;
  text-align: justify;
}

.color {
  margin-bottom: 10px;
}

label.radio {
  cursor: pointer;
}

label.radio input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}

label.radio span {
  padding: 2px 9px;
  border: 2px solid #ff0000;
  display: inline-block;
  color: #ff0000;
  border-radius: 3px;
  text-transform: uppercase;
}

label.radio input:checked + span {
  border-color: #ff0000;
  background-color: #ff0000;
  color: #fff;
}

.btn-danger {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.btn-danger:hover {
  background-color: #da0606 !important;
  border-color: #da0606 !important;
}

.btn-danger:focus {
  box-shadow: none;
}

.cart i {
  margin-right: 10px;
}

.productName {
  font-size: 35px;
  font-weight: 700;
  margin-top: 5px;
  color: #212529;
}

.priceStyle {
  color: #c00;
  font-weight: 600;
  font-size: 20px;
}

.btnStyling {
  margin-top: 16px;
  display: block;
}

.btnTextStyling {
  background: #224480;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.btnTextStyling:hover {
  background: #fff;
  color: #224480;
  border: 1px solid #224480;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.headingStyling {
  color: #224480;
}
