/* Listing Page  */
.Description {
  max-height: 50px;
  overflow-y: scroll;
  font-size: 15px;
}

.cart-button {
  background-color: #f2f2df;
  border-style: none;
  padding: 10px;
  width: 100%;
  color: rgb(109, 109, 109);
}

.edit-Button {
  background-color: #f2f2df;
  border-style: none;
  padding: 10px;
  width: 45%;
  color: rgb(109, 109, 109);
}

.delete-button {
  background-color: #f2f2df;
  border-style: none;
  padding: 10px;
  width: 45%;
  color: rgb(109, 109, 109);
}

.cartStyle {
  font-weight: 600;
  text-transform: uppercase;
}

.cartPriceStyle {
  font-weight: 400;
  font-size: 18px;
}

.viewDetailText {
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}

/* ---------GLOBAL MEDIA QUERY------------ */

/* @media screen and (min-width: 280px) {
  .Global-style {
      height: 120px;
  }
} */
.medicine-inner {
  border: 1px solid #064580;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.medicine-inner img {
  height: 300px;
  width: 100%;
  max-width: 100%;
}
.medicine-inner h4 {
  color: red;
  font-weight: bold;
  cursor: pointer;
}
.medicine-inner button {
  background-color: #064580;
  color: white;
  width: 50%;
  border-radius: 5px;
  border: none;
  padding: 5px;
}
