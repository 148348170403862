@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.App {
  text-align: center;
}

body {
  font-size: 17px;
  font-family: Roboto;
  font-weight: 400;
}

p {
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
}

ul li {
  margin-bottom: 7px;
  font-size: 18px;
}

.headingStyling {
  font-weight: 700;
  text-transform: capitalize;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Heading {
  font-family: sans-serif;
}

/* FOOTER FROM  HERE  */
.footer-siderbar {
  color: #4b666d;
  background-color: #a7e1f2;
  padding: 20px 0;
  font-family: sans-serif;
}

.footer-siderbar a {
  text-decoration: none;
  cursor: pointer;
}

.footer-widget a {
  text-decoration: none;
}

#footer {
  background-color: #a7e1f2;
  padding: 10px 0;
  text-decoration: none;
}

.latest-news a {
  list-style: none;
  text-decoration: none;
}

#social-icons {
  display: flex;
  justify-content: flex-start;
}

#social-icons li {
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  padding-left: 5px;
}

.widget ul li {
  list-style: none;
  padding: 5px 10px;
  margin: 3px 0;
}

.widget li {
  list-style: none;
  padding: 5px 10px;
  margin: 3px 0;
  font-size: 15px;
}

.Icon-Img {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

/* ------------------   ---------------- */
/* MEDIA QUERY FOR FOOTER  */
@media screen and (max-width: 700px) {
  .Names {
    flex-direction: column;
    margin: 5px 0px;
  }
}

/* ABOUT US   */

/* .Image {
  background-image: url("./Components/Main/aboutUsBanner.png");
} */

@media screen and (max-width: 900px) {
  .Main {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}

/* .myImage {
  background-image: url("./Components/Main/aboutUsBanner.png");
  height: 400px;
  background-size: cover;
} */

.iconImgStyle {
  width: 75px;
  height: 75px;
  margin-bottom: 15px;
}

.pointsStyling {
  line-height: 1.2;
  font-size: 18px;
  font-weight: 600;
}

.text-medium {
  font-size: 22px;
  line-height: 1.8;
  letter-spacing: 0.3px;
}

.text-blue {
  color: #6c19ff !important;
}

.subTitleStyle {
  font-weight: 700;
  text-transform: capitalize;
}

.widgetTitle {
  font-size: 18px;
  text-transform: capitalize;
}

.form-control:focus {
  border-color: #001232 !important;
  box-shadow: none !important;
}


