.rounded-t-5 {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

@media (max-width: 550px) {
  .rounded-tr-lg-0 {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0rem;
  }
}

/* .form-control:focus {
  border-color: black !important;
  box-shadow: none !important;
} */

.card-title {
  background: #a7cfdf;
  background: linear-gradient(to top, #a7cfdf 0%, #23538a 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 40px;
  font-family: sans-serif;
}

.PostProduct {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 15px;
}

::-webkit-input-placeholder {
  font-size: 14px;
  /* adjust to the desired font size */
  font-family: sans-serif;
}

/* LOGIN PAGE CSS  */

.login-button {
  background-color: #224480;
  border-style: none;
  padding: 10px;
  width: 60%;
  color: white;
}

h1 {
  color: rgb(109, 109, 109);
}

input {
  width: 60%;
  border-color: #91e4f3;
  border: 1px solid rgb(109, 109, 109);
  border-radius: 10px;
  padding: 7px;
  color: rgb(109, 109, 109);
  background-color: white;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.375rem;
  box-shadow: 0.15s;
}

@media screen and (max-width: 920px) {
  input {
    width: 80%;
  }
}

@media screen and (max-width: 920px) {
  .login-button {
    width: 80%;
  }
}

.file-container {
  min-width: 300px;
  width: 300px;
  /* height: 200px; */
  height: 178px;
  /* width: 100%;
  height: 200px; */
  text-align: center;
  background: #f6f8fb;
  border: 10px solid #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  margin: 10px;
}

/* div {
  position: relative;
  overflow: hidden;
}
input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
} */

.btn-Upload {
  position: relative;
  overflow: hidden;
}

.btn-Upload input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.My-Box {
  margin-top: 47px !important;
}
@media screen and (max-width: 768px) {
  .My-Box {
    margin-top: 0px !important;
  }
}
